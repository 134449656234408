import { IModelSetupModal, INanColumn, IStep3Data } from './ModelSetupModal.models';
import { FC, useState } from 'react';
import { StepIconProps } from '@mui/material/StepIcon';
import CheckIcon from '@mui/icons-material/Check';
import { icons, stepsCount, StepIconRoot, Connector } from './ModelSetupModal.constants';
import { Box, Card, CardContent, Dialog, DialogTitle, IconButton, Step, StepLabel, Stepper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  cardContentStyles,
  cardContentSx,
  dialogTitleStyles,
  iconButtonStyles,
  stepperStyles
} from './ModelSetupModal.styles';
import Step1 from './ModelSetupModalSteps/Step1/Step1.component';
import Step4 from './ModelSetupModalSteps/Step4/Step4.component';
import Step6 from './ModelSetupModalSteps/Step6/Step6.component';
import Step2 from './ModelSetupModalSteps/Step2/Step2.component';
import { removeLastExtension } from '../../helpers/removeExtension';
import Step32 from './ModelSetupModalSteps/Step32/Step32.component';
import Step52 from './ModelSetupModalSteps/Step52/Step52.component';
import { IBlock } from './ModelSetupModalSteps/Step52/Step52.models';
import { emptyBlock } from './ModelSetupModalSteps/Step52/Step52.helpers';

const ModelSetupModalComponent: FC<IModelSetupModal> = ({ setOpen, modelName, title, onClose }) => {
  const [activeStep, setActiveStep] = useState<number>(1);
  const [step1Data, setStep1Data] = useState<Object>({ uploadedFile: '' });
  const [step2Data, setStep2Data] = useState<Object>({
    problemType: '',
    modelType: '',
    targetColumn: ''
  });
  const [step3Data, setStep3Data] = useState<IStep3Data>({ isNAIYesOptionChecked: null, blocks: [] });
  const [step4Data, setStep4Data] = useState<Object>({ preprocessing: '', option: '', skip: true });
  const [step5Data, setStep5Data] = useState<any>({
    blocks: [],
    name: '',
    number: 0,
    allCheck: '',
    encoding: '',
    AllColumns: '',
    columns_top_categories: {},
    question_option: '',
    all_top_categories: {},
    skip: true
  });
  const [blocks, setBlocks] = useState<IBlock[]>(step5Data.blocks.length ? step5Data.blocks : [emptyBlock({ id: 1 })]);
  const [step6Data, setStep6Data] = useState<Object>({});
  const [arrayOfNumericalColumns, setArrayOfNumericalColumns] = useState([]);
  const [arrayOfCategoricalColumns, setArrayOfCategoricalColumns] = useState([]);
  const [arrayOfNumericalCategoricalNANColumns, setArrayOfNumericalCategoricalNANColumns] = useState<INanColumn[]>([]);

  function StepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    return (
      <StepIconRoot ownerState={{ completed, active }} className={className}>
        {completed && <CheckIcon />}
        {active && icons[Number(props.icon)]}
        {!active && !completed && icons[Number(props.icon)]}
      </StepIconRoot>
    );
  }

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          onClose();
        }
      }}
      aria-labelledby="simple-dialog-title"
      open
      PaperProps={{ style: { overflowX: 'hidden', height: '800px', padding: '0' } }}
    >
      <Box style={{ height: '100%' }}>
        <DialogTitle style={dialogTitleStyles} id="draggable-dialog-title">
          {title} - {removeLastExtension(modelName)}
          <IconButton onClick={onClose} sx={iconButtonStyles}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Card>
          <div style={stepperStyles}>
            <Stepper alternativeLabel activeStep={activeStep - 1} connector={<Connector />}>
              {stepsCount.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={StepIcon} />
                </Step>
              ))}
            </Stepper>
          </div>
          <CardContent sx={cardContentSx} style={cardContentStyles}>
            {activeStep === 1 && (
              <Step1
                setOpen={setOpen}
                modelName={modelName}
                setActiveStep={setActiveStep}
                step1Data={step1Data}
                setStep1Data={setStep1Data}
                setStep2Data={setStep2Data}
                setStep4Data={setStep4Data}
                setArrayOfNumericalColumns={setArrayOfNumericalColumns}
                setArrayOfCategoricalColumns={setArrayOfCategoricalColumns}
                setArrayOfNumericalCategoricalNANColumns={setArrayOfNumericalCategoricalNANColumns}
              />
            )}
            {activeStep === 2 && (
              <Step2
                arrayOfNumericalColumns={arrayOfNumericalColumns}
                setActiveStep={setActiveStep}
                step2Data={step2Data}
                setStep2Data={setStep2Data}
              />
            )}
            {activeStep === 3 && (
              <Step32
                arrayOfNumericalColumns={arrayOfNumericalColumns}
                arrayOfCategoricalColumns={arrayOfCategoricalColumns}
                arrayOfNumericalCategoricalNANColumns={arrayOfNumericalCategoricalNANColumns}
                setActiveStep={setActiveStep}
                step2Data={step2Data}
                step3Data={step3Data}
                setStep3Data={setStep3Data}
                step1Data={step1Data}
              />
            )}
            {activeStep === 4 && (
              <Step4
                setActiveStep={setActiveStep}
                setStep4Data={setStep4Data}
                step4Data={step4Data}
                step1Data={step1Data}
                arrayOfNumericalColumns={arrayOfNumericalColumns}
              />
            )}
            {activeStep === 5 && (
              <Step52
                blocks={blocks}
                setBlocks={setBlocks}
                setStep4Data={setStep4Data}
                arrayOfCategoricalColumns={arrayOfCategoricalColumns}
                setActiveStep={setActiveStep}
                setStep5Data={setStep5Data}
                step5Data={step5Data}
                step1Data={step1Data}
              />
            )}
            {activeStep === 6 && (
              <Step6
                modelName={modelName}
                onClose={onClose}
                step1Data={step1Data}
                step2Data={step2Data}
                step3Data={step3Data}
                step4Data={step4Data}
                step5Data={step5Data}
                step6Data={step6Data}
                setStep5Data={setStep5Data}
                setStep6Data={setStep6Data}
                setActiveStep={setActiveStep}
              />
            )}
          </CardContent>
        </Card>
      </Box>
    </Dialog>
  );
};

export default ModelSetupModalComponent;
