import React, { useEffect, useState } from 'react';
import { Formik, Form, useFormik } from 'formik';
import { Button, Grid, TextField } from '@mui/material';
import { initialValues } from './Step52.constants';
import {
  backButtonStyles,
  buttonContainerStyles,
  buttonStyles,
  containerStyles,
  contentStyles,
  description1Styles,
  descriptionStyles,
  formContainerStyles,
  formStyles,
  hintStyles,
  inputStyles,
  labelStyles,
  labelStylesLastChild,
  nextButtonStyles,
  noOptionStyles,
  questionOptionsStyles,
  radioButtonStyles,
  scrollBlockStyles,
  span1Styles,
  spanStyles,
  titleStyles
} from './Step52.styles';
import { IBlock, IStep5 } from './Step52.models';
import Step5Inner from './Step5Inner2/Step5Inner2.components';
import { PlusIconButton } from '../../../../components/Buttons/setupModelPlusButton';
import { emptyBlock } from '../Step52/Step52.helpers';
import { set } from 'husky';
import { removeLastExtension } from '../../../../helpers/removeExtension';

const Step52: React.FC<IStep5> = ({
  blocks,
  setBlocks,
  setStep4Data,
  arrayOfCategoricalColumns,
  step5Data,
  step1Data,
  setStep5Data,
  setActiveStep
}) => {
  const [dropdownOptions, setDropdownOptions] = useState<string[]>([]);

  const formik = useFormik({
    initialValues: {
      allCheck: step5Data.allCheck?.length > 0 ? step5Data.allCheck : '',
      encoding: step5Data.encoding?.length > 0 ? step5Data.encoding : '',
      allColumns: step5Data.allColumns?.length > 0 ? step5Data.allColumns : '',
      question_option: step5Data.question_option?.length > 0 ? step5Data.question_option : '',
      name: step5Data.name?.length > 0 ? step5Data.name : '',
      number: step5Data.number?.length > 0 ? step5Data.number : '',
      targetColumn: '',
      number_of_categories: '',
      all_top_categories: Object.keys(step5Data.all_top_categories).length > 0 ? step5Data.all_top_categories : '',
      blocks: []
    },
    onSubmit
  });

  const { values, setFieldValue, handleChange } = formik;

  function onNewRowSelected(value: any, newKey?: string, oldKey?: string) {
    if (values.question_option === 'no') {
      let newData = { ...step5Data.columns_top_categories };

      if (oldKey && oldKey !== newKey) {
        delete newData[oldKey];
      }
      if (newKey) {
        newData[String(newKey)] = value; // Ensure newKey is a string
      }

      setStep5Data({ ...step5Data, columns_top_categories: newData });
    } else {
      let newData = { ...step5Data.all_top_categories };
      newData['all_top_categories'] = value;

      setStep5Data({ ...step5Data, all_top_categories: newData });
    }
  }

  function onInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    const numericValue = parseInt(value);

    if ((numericValue >= 1 && numericValue <= 10) || value === '') {
      setFieldValue('number', value);
      onNewRowSelected({ top: numericValue, category_name: step5Data.name });
    }
  }

  function onSecondInputChange(event: any) {
    const value = event.target.value;
    const filteredValue = value.replace(/\d/g, ''); // Remove all numeric characters
    setFieldValue('name', filteredValue);
    onNewRowSelected({ top: parseInt(step5Data.number), category_name: filteredValue });
  }

  function onSubmit() {
    setActiveStep(6);
    setStep5Data({ ...step5Data, blocks });

    // if (step5Data.allColumns === 'yes') {
    //   setStep5Data({ ...step5Data, columns_top_categories: {} });
    // }
    //
    // if (step5Data.allColumns === 'no') {
    //   setStep5Data({ ...step5Data, all_top_categories: {} });
    // }

    // if (step5Data.encoding.length > 0) {
    //   if (step5Data.allColumns.length === 'no') {
    //     setStep5Data({ ...step5Data, columns_top_categories: null });
    //     setBlocks([]);
    //   } else {
    //     if (step5Data.question_option === 'yes' && Object.keys(step5Data.all_top_categories).length > 0) {
    //       setStep5Data({ ...step5Data, skip: false, columns_top_categories: {} });
    //       setBlocks([emptyBlock()]);
    //     }
    //     if (step5Data.question_option === 'no') {
    //       setStep5Data({ ...step5Data, skip: false, name: '', number: '', all_top_categories: {} });
    //     }
    //     if (
    //       Object.keys(step5Data.columns_top_categories).length < 0 &&
    //       Object.keys(step5Data.all_top_categories).length < 0
    //     ) {
    //       setStep5Data({ ...step5Data, skip: false, all_top_categories: {}, columns_top_categories: {} });
    //     }
    //   }
    // } else {
    //   setStep5Data({ ...step5Data, skip: true, columns_top_categories: {}, all_top_categories: {} });
    // }
  }
  useEffect(() => {
    if (step5Data.question_option === 'no') {
      setStep5Data({ ...step5Data, name: '', number: '' });
    }
    setStep5Data({
      ...step5Data,
      name: values.name,
      number: values.number,
      allCheck: values.allCheck,
      encoding: values.encoding,
      allColumns: values.allColumns,
      question_option: values.question_option
    });
  }, [values]);

  const onPlusClick = () => {
    if (!blocks.length) {
      setBlocks([emptyBlock()]);
    } else if (blocks.length < 10) {
      setBlocks((prev: any) => [...prev, emptyBlock({ id: blocks[blocks.length - 1].id + 1 })]);
    }
  };

  function updateCurrentBlock(currentBlock: IBlock) {
    const newBlocks = blocks.map((block: any) => {
      if (block.id === currentBlock.id) {
        return { ...currentBlock };
      }

      return { ...block };
    });

    setBlocks(newBlocks);
  }

  const deleteBlock = (blockId: any, key: string) => {
    setBlocks((prev: any) => prev.filter((block: any) => block.id !== blockId));
  };

  function onBackClick() {
    setActiveStep(4);
  }

  useEffect(() => {
    const newDropdownOptions = arrayOfCategoricalColumns.filter(
      (option: any) => !blocks.some((block: any) => block.dropdownValue === option)
    );
    setDropdownOptions(newDropdownOptions);
  }, [blocks, arrayOfCategoricalColumns]);

  return (
    <Formik style={{ width: '100px' }} initialValues={initialValues} onSubmit={onSubmit}>
      <Form style={formContainerStyles}>
        <Grid item xs={12} md={12} style={containerStyles}>
          <div style={contentStyles}>
            <Grid item xs={12}>
              <h2 style={titleStyles}>Categorical encoding</h2>
            </Grid>
            {Object.keys(arrayOfCategoricalColumns).length === 0 ? (
              <h3 style={span1Styles}>
                '{removeLastExtension(step1Data.uploadedFile)}' does not contain any categorical columns.
              </h3>
            ) : (
              <>
                <div style={scrollBlockStyles}>
                  <Grid item xs={12} md={12} style={formStyles}>
                    <h3 style={descriptionStyles}>
                      Have you applied any categorical encoding method to all the categorical columns?
                    </h3>
                    <div style={radioButtonStyles}>
                      <label style={labelStyles}>
                        <input
                          type="radio"
                          checked={values.allCheck === 'no'}
                          name="allCheck"
                          value="no"
                          onChange={handleChange}
                          style={inputStyles}
                        />
                        <span style={spanStyles}>No</span>
                      </label>
                      <label style={labelStylesLastChild}>
                        <input
                          type="radio"
                          checked={values.allCheck === 'yes'}
                          name="allCheck"
                          value="yes"
                          onChange={handleChange}
                          style={inputStyles}
                        />
                        <span style={spanStyles}>Yes</span>
                      </label>
                    </div>
                  </Grid>
                  {values.allCheck === 'yes' && (
                    <>
                      <Grid item xs={12} md={12} style={formStyles}>
                        <h3 style={descriptionStyles}>Which method?</h3>
                        <div style={radioButtonStyles}>
                          <label style={labelStyles}>
                            <input
                              type="radio"
                              checked={values.encoding === 'catboost'}
                              name="encoding"
                              value="catboost"
                              onChange={handleChange}
                              style={inputStyles}
                            />
                            <span style={spanStyles}>Catboost encoder</span>
                          </label>
                          <label style={labelStylesLastChild}>
                            <input
                              type="radio"
                              checked={values.encoding === 'one_hot'}
                              name="encoding"
                              value="one_hot"
                              onChange={handleChange}
                              style={inputStyles}
                            />
                            <span style={spanStyles}>One Hot</span>
                          </label>
                        </div>
                      </Grid>
                      {values.encoding.length > 0 && (
                        <>
                          <Grid item xs={12} md={12} style={formStyles}>
                            <h3 style={spanStyles}>
                              {`Have you selected the top categories and grouped the remaining ones into a single category
  before making ${
    values.encoding === 'catboost' ? 'Catboost' : values.encoding === 'one_hot' ? 'One Hot' : values.encoding
  } encoding?`}
                            </h3>
                            <div style={questionOptionsStyles}>
                              <label style={radioButtonStyles}>
                                <input
                                  type="radio"
                                  checked={values.allColumns === 'no'}
                                  name="allColumns"
                                  value="no"
                                  onChange={handleChange}
                                  style={inputStyles}
                                />
                                <span style={spanStyles}>No</span>
                              </label>
                              <label style={radioButtonStyles}>
                                <input
                                  type="radio"
                                  checked={values.allColumns === 'yes'}
                                  name="allColumns"
                                  value="yes"
                                  onChange={handleChange}
                                  style={inputStyles}
                                />
                                <span style={spanStyles}>Yes</span>
                              </label>
                            </div>
                          </Grid>
                          {values.allColumns === 'yes' && (
                            <>
                              <Grid item xs={12} md={12} style={formStyles}>
                                <h3 style={spanStyles}>
                                  Have you set the same number of top categories and the same category name for all
                                  categorical columns?
                                </h3>
                                <div style={questionOptionsStyles}>
                                  <label style={radioButtonStyles}>
                                    <input
                                      type="radio"
                                      checked={values.question_option === 'no'}
                                      name="question_option"
                                      value="no"
                                      onChange={handleChange}
                                      style={inputStyles}
                                    />
                                    <span style={spanStyles}>No</span>
                                  </label>
                                  <label style={radioButtonStyles}>
                                    <input
                                      type="radio"
                                      checked={values.question_option === 'yes'}
                                      name="question_option"
                                      value="yes"
                                      onChange={handleChange}
                                      style={inputStyles}
                                    />
                                    <span style={spanStyles}>Yes</span>
                                  </label>
                                </div>
                              </Grid>
                              {values.question_option.length > 0 && (
                                <>
                                  {values.question_option === 'no' ? (
                                    <>
                                      <div>
                                        {blocks.map((block: any) => (
                                          <div style={{ width: '100%' }} key={block.id}>
                                            <Step5Inner
                                              firstLabel={'Select Column'}
                                              dropDownOptions={dropdownOptions}
                                              block={block}
                                              updateCurrentBlock={updateCurrentBlock}
                                              deleteBlock={deleteBlock}
                                            />
                                          </div>
                                        ))}
                                      </div>
                                      <PlusIconButton handlePlusButtonClick={onPlusClick} title="Add a new column" />
                                    </>
                                  ) : (
                                    <>
                                      <Grid item xs={12} md={12} style={noOptionStyles}>
                                        <Grid style={{ width: '100%' }}>
                                          <p
                                            style={{
                                              color: '#7f7f7f',
                                              margin: '6px 0',
                                              fontSize: '16px',
                                              fontWeight: '400',
                                              fontFamily: 'Circular Std Book'
                                            }}
                                          >
                                            Number of top categories
                                          </p>
                                          <TextField
                                            type="number"
                                            style={{ width: '100%' }}
                                            value={values.number}
                                            onChange={onInputChange}
                                            onKeyDown={(e) => {
                                              if (
                                                e.key === '-' ||
                                                e.key === 'e' ||
                                                e.key === 'E' ||
                                                e.key === '+' ||
                                                e.key === '.'
                                              ) {
                                                e.preventDefault();
                                              }
                                            }}
                                            inputProps={{ min: 1, max: 10 }}
                                            // placeholder="Number of top categories"
                                          />
                                        </Grid>
                                        <Grid style={{ width: '100%' }}>
                                          <p
                                            style={{
                                              color: '#7f7f7f',
                                              margin: '6px 0',
                                              fontSize: '16px',
                                              fontWeight: '400',
                                              fontFamily: 'Circular Std Book',
                                              paddingLeft: '20px'
                                            }}
                                          >
                                            Name of grouped categories
                                          </p>
                                          <TextField
                                            style={{ width: '100%', marginLeft: '20px' }}
                                            value={values.name}
                                            onChange={onSecondInputChange}
                                            inputProps={{ maxLength: 20 }}
                                            // placeholder="Enter category name"
                                          />
                                        </Grid>
                                      </Grid>
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </>
            )}
          </div>
          <Grid item xs={12} md={12} sx={buttonContainerStyles}>
            <Button variant="contained" size="large" style={backButtonStyles} sx={buttonStyles} onClick={onBackClick}>
              Back
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="large"
              style={{
                backgroundColor:
                  !values.allCheck && Object.keys(arrayOfCategoricalColumns).length > 0 ? '#d9d9d9' : '#334063',
                color: !values.allCheck && Object.keys(arrayOfCategoricalColumns).length > 0 ? '#635F5F' : '#fff',
                // backgroundColor: !values.allCheck ? '#d9d9d9' : '#334063',
                // color: !values.allCheck ? '#635F5F' : '#fff',
                width: '120px',
                height: '60px',
                borderRadius: '8px',
                fontFamily: 'Circular Std Book',
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: 'normal',
                float: 'right'
              }}
              sx={buttonStyles}
              onClick={onSubmit}
              disabled={!values.allCheck && Object.keys(arrayOfCategoricalColumns).length > 0}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
};

export default Step52;
