import React from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

export const formContainerStyles: React.CSSProperties = {
  width: '100%'
};

export const containerStyles: React.CSSProperties = {
  flexDirection: 'column',
  padding: '0 50px',
  display: 'flex',
  color: '#000',
  position: 'relative'
};

export const contentStyles: React.CSSProperties = {
  height: '500px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '50%',
  margin: '0 auto'
};

export const descriptionStyles: React.CSSProperties = {
  margin: '0',
  display: 'flex',
  color: '#000',
  fontFamily: 'Circular Std Book',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  justifyContent: 'center'
};

export const titleStyles: React.CSSProperties = {
  margin: '0 0 40px 0',
  display: 'flex',
  justifyContent: 'center',
  color: '#000',
  fontFamily: 'Circular Std Book',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal'
};

export const formStyles: React.CSSProperties = {
  marginTop: '15px',
  display: 'flex',
  justifyContent: 'center'
};

export const dropdownStyles: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '100%'
};
export const autocompleteStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'end'
};

export const radioButtonStyles: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center'
};
export const inputStyles: React.CSSProperties = {
  width: '18px',
  height: '18px',
  fill: '#FFF',
  strokeWidth: '1px',
  stroke: '#000',
  marginRight: '5px',
  accentColor: '#334063'
};

export const spanStyles: React.CSSProperties = {
  color: '#333333',
  fontFamily: 'Circular Std Book',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  marginRight: '45px',
  paddingTop: '4px'
};

export const spanLastStyles: React.CSSProperties = {
  color: '#333333',
  fontFamily: 'Circular Std Book',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  paddingTop: '4px'
};

export const span1Styles: React.CSSProperties = {
  color: '#E9271B',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '20px',
  fontFamily: 'Circular Std Book',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  textAlign: 'center'
};

export const scrollBlockStyles: React.CSSProperties = {
  height: '310px',
  overflowY: 'auto',
  overflowX: 'hidden',
  width: '100%'
};

export const backButtonStyles: React.CSSProperties = {
  width: '120px',
  height: '60px',
  borderRadius: '8px',
  fontFamily: 'Circular Std Book',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  backgroundColor: '#334063',
  color: '#fff'
};

export const nextButtonStyles: React.CSSProperties = {
  width: '120px',
  height: '60px',
  borderRadius: '8px',
  fontFamily: 'Circular Std Book',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  backgroundColor: '#334063',
  color: '#fff',
  float: 'right'
};

export const buttonContainerStyles: SxProps<Theme> = {};

export const buttonStyles: SxProps<Theme> = {
  margin: '20px auto',
  height: '52px'
};
