import resourcesManager from './resources/resourcesManager';
import snowflakeTestConnectionManager from './snowflakeTestConnection/snowflakeTestConnectionManager';
import snowflakeGetQueryManager from './snowflakeGetQuery/snowflakeGetQueryManager';
import snowflakePushDataToAzureManager from './snowflakePushDataToAzure/snowFlakePushDataToAzureManager';
import snowflakeDataPushStatusManager from './snowflakedataPushStatus/snowflakeDataPushStatusManager';
import usersManager from './users/usersManager';
import authManager from './auth/authManager';
import healthManager from './health/healthManager';
import uploadManager from './upload/uploadManager';
import uploadStatusManager from './uploadStatus/uploadStatusManager';
import uploadModelStatusManager from './uploadModelStatus/uploadModelStatusManager';
import downloadManager from './download/downloadManager';
import deleteManager from './delete/deleteManager';
import summaryManager from './summary/summaryManager';
import datasourceColumnsManager from './datasourceColumns/datasourceColumnsManager';
import datasourceUsersManager from './datasourceUsers/datasourceUsersManager';
import modelTrainManager from './modelTrain/modelTrainManager';
import modelAddingManager from './modelAdding/modelAddingManager';
import modelAddingStatusManager from './modelAddingStatus/modelAddingStatusManager';
import trainStatusManager from './trainStatus/trainStatusManager';
import trainProgressManager from './trainProgress/trainProgressManager';
import userModelsManager from './userModels/userModelsManager';
import predictionManager from './prediction/predictionManager';
import predictionProgressManager from './predictionProgress/predictionProgressManager';
import predictionStatusManager from './predictionStatus/predictionStatusManager';
import modelListManager from './modelList/modelListManager';
import modelViewManager from './modelView/modelViewManager';
import modelSettingsManager from './modelSettings/modelSettingsManager';
import modelSummaryManager from './modelSummary/modelSummaryManager';
import previewManager from './preview/previewManager';
import storageManager from './storage/storageManager';
import actionsPreviewManager from './actionsPreview/actionsPreviewManager';
import previewStatusManager from './previewStatus/previewStatusSaveManager';
import deployManager from './deploy/deployManager';
import undeployManager from './undeploy/undeployManager';
import deployStatusManager from './deployStatus/deployStatusManager';
import viewUrlManager from './viewUrl/viewUrlManager';
import uploadModelManager from './upladModel/uploadModelManager';
import uploadDatasetManager from './uploadDataset/uploadDatasetManager';

const API = {
  auth: authManager,
  health: healthManager,
  users: usersManager,
  resources: resourcesManager,
  snowflakeTestConnection: snowflakeTestConnectionManager,
  snowflakeGetQuery: snowflakeGetQueryManager,
  snowflakePushDataToAzure: snowflakePushDataToAzureManager,
  snowflakeDataPushStatus: snowflakeDataPushStatusManager,
  upload: uploadManager,
  modelAdding: modelAddingManager,
  uploadStatus: uploadStatusManager,
  uploadModelStatus: uploadModelStatusManager,
  modelAddingStatus: modelAddingStatusManager,
  download: downloadManager,
  delete: deleteManager,
  summary: summaryManager,
  datasourceColumns: datasourceColumnsManager,
  datasourceUsers: datasourceUsersManager,
  modelTrain: modelTrainManager,
  trainStatus: trainStatusManager,
  trainProgress: trainProgressManager,
  userModels: userModelsManager,
  prediction: predictionManager,
  predictionProgress: predictionProgressManager,
  predictionStatus: predictionStatusManager,
  modelList: modelListManager,
  modelView: modelViewManager,
  modelSettings: modelSettingsManager,
  modelSummary: modelSummaryManager,
  preview: previewManager,
  storage: storageManager,
  actionsPreview: actionsPreviewManager,
  previewStatus: previewStatusManager,
  deploy: deployManager,
  undeploy: undeployManager,
  deployStatus: deployStatusManager,
  viewUrl: viewUrlManager,
  uploadModel: uploadModelManager,
  uploadDataset: uploadDatasetManager
};

export default API;
