import React from 'react';
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material';
import { IStep3Inner2 } from './Step3Inner2.models';
import CloseIcon from '@mui/icons-material/Close';
import { containerStyles, dropdownRadioStyles, dropdownStyles, removeIconStyles } from './Step3Inner2.styles';
import { emptyBlock } from '../Step32.helpers';
import { INanColumn } from '../../../ModelSetupModal.models';

const Step3Inner2: React.FC<IStep3Inner2> = ({
  firstLabel,
  dropDownOptions,
  block,
  updateCurrentBlock,
  deleteBlock
}) => {
  function onColumnNameSelect(event: any, value: string | null) {
    const selectedOption = dropDownOptions.find((option) => option.name === value);
    if (!selectedOption || !value) return;
    const newBlock = emptyBlock({ id: block.id, inputValue: value, category: selectedOption.type });
    updateCurrentBlock(newBlock);
  }

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const newBlock = { ...block, radioButtonValue: value, customValue: '' };
    updateCurrentBlock(newBlock);
  };

  const handleCustomValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (block.category !== 'object') {
      const filteredValue = value.slice(0, 20).replace(/[^\d.]/g, '');
      const newBlock = { ...block, customValue: parseFloat(filteredValue) };
      updateCurrentBlock(newBlock);
    } else {
      const value = event.target.value;
      const filteredValue = value.replace(/\d/g, ''); // Remove all numeric characters
      const newBlock = { ...block, customValue: filteredValue };
      updateCurrentBlock(newBlock);
    }
  };

  return (
    <Grid style={containerStyles}>
      <div style={dropdownRadioStyles}>
        <Grid item xs={6} style={dropdownStyles}>
          <p
            style={{
              color: '#333333',
              margin: '6px 0',
              width: '50%',
              fontSize: '16px',
              fontWeight: '400',
              fontFamily: 'Circular Std Book'
            }}
          >
            {firstLabel}
          </p>
          <Autocomplete
            options={dropDownOptions.map((option: INanColumn) => option.name)}
            value={block.inputValue}
            renderInput={(params) => <TextField {...params} />}
            clearOnEscape={false}
            clearIcon={null}
            fullWidth
            style={{ width: '100%' }}
            onChange={onColumnNameSelect}
          />
        </Grid>
        <Grid style={{ display: 'flex', position: 'relative' }} item xs={6}>
          {block.inputValue && (
            <FormControl component="fieldset">
              <RadioGroup row name="actions" value={block.radioButtonValue} onChange={handleRadioChange}>
                {block.category !== 'object' ? (
                  <>
                    <FormControlLabel
                      style={{ fontSize: '16px', marginRight: '8px' }}
                      value="mean"
                      control={<Radio />}
                      label="Mean"
                    />
                    <FormControlLabel
                      style={{ fontSize: '16px', marginRight: '8px' }}
                      value="median"
                      control={<Radio />}
                      label="Median"
                    />
                    <FormControlLabel
                      style={{ fontSize: '16px', marginRight: '8px' }}
                      value="custom value"
                      control={<Radio />}
                      label="Custom Value"
                    />
                  </>
                ) : (
                  <>
                    <FormControlLabel
                      style={{ fontSize: '16px', marginRight: '8px' }}
                      value="mode"
                      control={<Radio />}
                      label="Mode"
                    />
                    <FormControlLabel
                      style={{ fontSize: '16px', marginRight: '8px' }}
                      value="custom value"
                      control={<Radio />}
                      label="Custom Value"
                    />
                  </>
                )}
                {block.radioButtonValue === 'custom value' && (
                  <TextField
                    style={{
                      // position: 'absolute',
                      // right: '-160px',
                      width: '155px',
                      border: 'none',
                      backgroundColor: 'transparent',
                      padding: '0',
                      marginTop: '6px'
                    }}
                    type={block.category !== 'object' ? 'number' : 'text'}
                    value={block.customValue}
                    onChange={handleCustomValueChange}
                    margin="normal"
                    fullWidth
                    onKeyDown={(e) => {
                      if (block.category !== 'object') {
                        if (e.key === '-' || e.key === 'e' || e.key === 'E' || e.key === '+') {
                          e.preventDefault();
                        }
                      }
                    }}
                    inputProps={{
                      maxLength: 20,
                      style: { height: '0px', borderRadius: '0 !important' }
                    }} // Ensure the user cannot type more than 20 characters
                  />
                )}
              </RadioGroup>
            </FormControl>
          )}
        </Grid>
      </div>
      <IconButton style={removeIconStyles} onClick={() => deleteBlock(block.id, block.inputValue)} disableRipple={true}>
        <CloseIcon />
      </IconButton>
    </Grid>
  );
};

export default Step3Inner2;
