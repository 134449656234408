import React, { useEffect, useState } from 'react';
import { Formik, Form, useFormik } from 'formik';
import { Autocomplete, Button, CircularProgress, Grid, TextField } from '@mui/material';
import { validationSchema, initialValues } from './Step1.constants';
import { IStep1 } from './Step1.models';
import {
  buttonContainerStyles,
  buttonStyles,
  containerStyles,
  contentStyles,
  descriptionStyles,
  dropdownStyles,
  formContainerStyles,
  orStyles,
  redirectParentStyles,
  redirectStyles,
  titleStyles
} from './Step1.styles';
import { dispatch, useAppSelector } from '../../../../store/hooks';
import { datasourceUsersMiddleware, datasourceUsersSelector } from '../../../../store/slices/datasourceUsers';
import { summaryMiddleware, summarySelector } from '../../../../store/slices/summary';
import { useNavigate } from 'react-router-dom';
import { modelAddingMiddleware } from '../../../../store/slices/modelAdding';
import { removeLastExtension } from '../../../../helpers/removeExtension';

const Step1: React.FC<IStep1> = ({
  setOpen,
  setArrayOfNumericalColumns,
  setArrayOfCategoricalColumns,
  setArrayOfNumericalCategoricalNANColumns,
  step1Data,
  setStep2Data,
  setStep4Data,
  setStep1Data,
  setActiveStep
}) => {
  const [isResponse, setIsResponse] = useState<boolean>(false);
  const { datasourceUsersData } = useAppSelector(datasourceUsersSelector.DatasourceUsersData);
  const { summaryData, summaryStatus } = useAppSelector(summarySelector.summaryData);
  const userId = localStorage.getItem('userId');
  const getDatasourceUsersURL = `/datasource/user_datasources?container_name=containerfromblobservice${userId}`;
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true); // Add loading state

  const datasetOptionsWithIds = datasourceUsersData.map((option: any, index: any) => ({
    id: option,
    label: removeLastExtension(option)
  }));

  const formik = useFormik({
    initialValues: {
      uploadedFile: step1Data.uploadedFile.length > 0 ? step1Data.uploadedFile : ''
    },
    validationSchema,
    onSubmit
  });

  const { values, setFieldValue } = formik;

  const getSummaryUrl = `/data_operations/data_summary?container_name=containerfromblobservice${userId}&file_name=${values.uploadedFile}`;

  function getCategoricalColumns(data: any) {
    return data.filter((item: any) => item.type === 'object').map((item: any) => item.column_name);
  }

  function getNumericalColumns(data: any) {
    return data.filter((item: any) => item.type !== 'object').map((item: any) => item.column_name);
  }

  function getNANColumns(data: any) {
    return data
      .filter((item: any) => parseInt(item.na_values, 10) > 0)
      .map((item: any) => ({ type: item.type, name: item.column_name }));
  }

  const handleAutocompleteChange = (selectedOption: any) => {
    const value = selectedOption.id;
    setStep2Data({ problemType: 'classification', modelType: '', targetColumn: '' });
    setStep4Data({
      preprocessing: '',
      skip: true
    });
    setArrayOfNumericalColumns([]);

    setFieldValue('uploadedFile', value);
  };

  const handleRedirect = () => {
    setOpen(false);
    navigate('/resources'); // Use navigate to redirect
  };

  useEffect(() => {
    setStep1Data({ ...values });
  }, [values]);

  async function onSubmit() {
    setActiveStep(2);
    setIsResponse(true);
    const data = await dispatch(summaryMiddleware.getSummary(getSummaryUrl));
    setArrayOfCategoricalColumns(getCategoricalColumns(data?.data.summary?.data_summary));
    setArrayOfNumericalColumns(getNumericalColumns(data?.data.summary?.data_summary));
    setArrayOfNumericalCategoricalNANColumns(getNANColumns(data?.data.summary?.data_summary));
    setIsResponse(false);
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true before dispatching
      await dispatch(datasourceUsersMiddleware.getDatasourceUsers(getDatasourceUsersURL));
      setLoading(false); // Set loading to false after dispatch completes
    };

    fetchData(); // Call fetchData inside the useEffect to ensure the promise is handled
  }, []);

  return (
    <>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        <Form style={formContainerStyles}>
          <Grid item xs={12} md={12} style={containerStyles}>
            <div style={contentStyles}>
              <div>
                <h2 style={titleStyles}>Select Dataset</h2>
                <h2 style={descriptionStyles}>Please select the initial dataset used in the model training process</h2>
              </div>
              <Grid item xs={12} sx={{ paddingX: '16px' }} style={dropdownStyles}>
                <Autocomplete
                  value={removeLastExtension(values.uploadedFile)}
                  options={datasetOptionsWithIds}
                  onChange={(event, newValue) => handleAutocompleteChange(newValue)}
                  loading={loading}
                  noOptionsText={loading ? <CircularProgress size={24} /> : 'No options'} // Ensure CircularProgress is shown when loading
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="File name"
                      label=""
                      InputProps={{
                        ...params.InputProps,
                        style: { fontFamily: 'Circular Std Book' }
                      }}
                    />
                  )}
                  componentsProps={{
                    clearIndicator: {
                      sx: { display: 'none' }
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12} sx={{ justifyContent: 'flex-end' }}>
                <span style={redirectParentStyles}>
                  <span style={orStyles}>or </span>
                  <span style={redirectStyles} onClick={handleRedirect}>
                    Go to Datasets page to upload dataset
                  </span>
                </span>
              </Grid>
            </div>
            <Grid item xs={12} md={12} sx={buttonContainerStyles}>
              <Button
                type="submit"
                variant="contained"
                size="large"
                style={{
                  width: '120px',
                  height: '60px',
                  borderRadius: '8px',
                  fontFamily: 'Circular Std Book',
                  fontSize: '24px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: 'normal',
                  backgroundColor: values.uploadedFile.length === 0 ? '#d9d9d9' : '#334063',
                  color: values.uploadedFile.length === 0 ? '#635F5F' : '#fff',
                  float: 'right'
                }}
                sx={buttonStyles}
                onClick={onSubmit}
                disabled={values.uploadedFile.length === 0}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </>
  );
};

export default Step1;
