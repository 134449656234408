import React, { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Typography
} from '@mui/material';

import { dispatch, useAppSelector } from '../../store/hooks';
import { modalsMiddleware } from '../../store/slices/modals';
import { ModalName } from '../../types/modals';
import LineChart from '../Charts/LineChart';
import ModelSummaryStyled from '../StyledComponents/ModelSummaryStyled';
import { modelSummaryMiddleware, modelSummarySelector } from '../../store/slices/modelSummary';
import { setModelSummaryData, setModelSummaryStatus } from './../../store/slices/modelSummary/middleware';
import RegressionChart from '../Charts/RegressionChart';
import { MbConverter } from '../../helpers/MbConverter';
import { removeLastExtension } from '../../helpers/removeExtension';

export interface ModelManagerSummaryDialogProps {
  fileName: string;
  fileSize: number;
}

// eslint-disable-next-line max-lines-per-function
const ModelManagerSummaryDialog = ({ fileName, fileSize }: ModelManagerSummaryDialogProps) => {
  const userId = localStorage.getItem('userId');
  const modelSummarySummaryUrl = `/model_summary/get_summary?container_name=containerfromblobservice${userId}&model_name=${fileName}`;
  const { modelSummaryStatus } = useAppSelector(modelSummarySelector.modelSummaryData);
  const { modelSummaryData } = useAppSelector(modelSummarySelector.modelSummaryData);
  useEffect(() => {
    dispatch(modelSummaryMiddleware.getModelSummary(modelSummarySummaryUrl));
  }, [modelSummarySummaryUrl]);

  const onModalClose = () => {
    dispatch(modalsMiddleware.closeModal(ModalName.ModelManagerSummaryDialog));
    dispatch(setModelSummaryData({}));
    dispatch(setModelSummaryStatus(0));
  };

  return (
    <Dialog fullWidth maxWidth="lg" onClose={onModalClose} aria-labelledby="simple-dialog-title" open>
      <ModelSummaryStyled>
        <DialogTitle className="dialog-title" id="draggable-dialog-title">
          Model Summary
          <IconButton onClick={onModalClose} className="close-button">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogTitle className="dialog-title" id="draggable-dialog-title">
          {`${removeLastExtension(fileName)} / ${MbConverter(fileSize)}`}
        </DialogTitle>
        {modelSummaryStatus === 200 ? (
          <Card>
            <CardContent sx={{ pt: 0 }}>
              <Grid container spacing={2} columns={12}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    color: '#1E1E1E',
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    margin: '5px'
                  }}
                >
                  <Box>
                    <Box sx={{ display: 'flex' }}>
                      <Typography sx={{ marginRight: '5px', fontWeight: 600 }}>Dataset -</Typography>
                      <Typography>
                        {removeLastExtension(modelSummaryData.successfully.summary?.model_info.Data)}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                      <Typography sx={{ marginRight: '5px', fontWeight: 600 }}>Training Date -</Typography>
                      <Typography>{modelSummaryData.successfully.summary?.model_info.Train_date}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                      <Typography sx={{ marginRight: '5px', fontWeight: 600 }}>Model -</Typography>
                      <Typography>{modelSummaryData.successfully.summary?.model_info.Model}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                      <Typography sx={{ marginRight: '5px', fontWeight: 600 }}>Problem type -</Typography>
                      <Typography>{modelSummaryData.successfully.summary?.model_info?.Problem_type}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                      <Typography sx={{ marginRight: '5px', fontWeight: 600 }}>Target variable -</Typography>
                      <Typography>{modelSummaryData.successfully.summary?.model_info?.Target_variable}</Typography>
                    </Box>
                    {modelSummaryData.successfully.summary.model_info.Problem_type === 'classification' && (
                      <Box sx={{ display: 'flex' }}>
                        <Typography sx={{ marginRight: '5px', fontWeight: 600 }}>Optimal threshold -</Typography>
                        <Typography>{modelSummaryData.successfully.summary?.model_info?.Optimal_threshold}</Typography>
                      </Box>
                    )}
                  </Box>
                  <Box>
                    <Typography sx={{ fontWeight: 900 }}>Model performance metrics:</Typography>
                    {modelSummaryData.successfully.summary?.model_info.Problem_type === 'regression' && (
                      <Box sx={{ display: 'flex' }}>
                        <Typography sx={{ marginRight: '5px', fontWeight: 600 }}>RMSE =</Typography>
                        <Typography>
                          {parseFloat(modelSummaryData.successfully.summary?.Model_performance_metrics?.RMSE).toFixed(
                            2
                          )}
                        </Typography>
                      </Box>
                    )}
                    <Box sx={{ display: 'flex' }}>
                      <Typography sx={{ marginRight: '5px', fontWeight: 600 }}>
                        {modelSummaryData.successfully.summary?.model_info.Problem_type === 'classification'
                          ? 'Recall = '
                          : 'MSE = '}
                      </Typography>
                      <Typography>
                        {modelSummaryData.successfully.summary?.model_info.Problem_type === 'classification'
                          ? `${modelSummaryData.successfully.summary?.Model_performance_metrics?.Recall}%`
                          : `${parseFloat(
                              modelSummaryData.successfully.summary?.Model_performance_metrics?.MSE
                            ).toFixed(2)}`}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                      <Typography sx={{ marginRight: '5px', fontWeight: 600 }}>
                        {modelSummaryData.successfully.summary?.model_info?.Problem_type === 'classification'
                          ? 'Precision = '
                          : 'MAE = '}
                      </Typography>
                      <Typography>
                        {modelSummaryData.successfully.summary?.model_info?.Problem_type === 'classification'
                          ? `${modelSummaryData.successfully.summary?.Model_performance_metrics?.Precision}%`
                          : `${parseFloat(
                              modelSummaryData.successfully.summary?.Model_performance_metrics?.MAE
                            ).toFixed(2)}`}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                      <Typography sx={{ marginRight: '5px', fontWeight: 600 }}>
                        {modelSummaryData.successfully.summary?.model_info?.Problem_type === 'classification'
                          ? 'F1-score = '
                          : 'R-Squared = '}
                      </Typography>
                      <Typography>
                        {modelSummaryData.successfully.summary?.model_info?.Problem_type === 'classification'
                          ? `${modelSummaryData.successfully.summary?.Model_performance_metrics?.F1_score}%`
                          : `${parseFloat(modelSummaryData.successfully.summary?.Model_performance_metrics?.R2).toFixed(
                              2
                            )}`}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                      <Typography sx={{ marginRight: '5px', fontWeight: 600 }}>
                        {modelSummaryData.successfully.summary?.model_info?.Problem_type === 'classification'
                          ? 'Accuracy = '
                          : 'Adjusted R-Squared = '}
                      </Typography>
                      <Typography>
                        {modelSummaryData.successfully.summary?.model_info?.Problem_type === 'classification'
                          ? `${modelSummaryData.successfully.summary?.Model_performance_metrics?.Accuracy}%`
                          : `${parseFloat(
                              modelSummaryData.successfully.summary?.Model_performance_metrics?.Adjusted_R2
                            ).toFixed(2)}`}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                      <Typography sx={{ marginRight: '5px', fontWeight: 600 }}>
                        {modelSummaryData.successfully.summary?.model_info?.Problem_type === 'classification'
                          ? 'AUC = '
                          : null}
                      </Typography>
                      <Typography>
                        {modelSummaryData.successfully.summary?.model_info?.Problem_type === 'classification'
                          ? `${modelSummaryData.successfully.summary?.Model_performance_metrics?.AUC}%`
                          : null}
                      </Typography>
                    </Box>
                    {/*{modelSummaryData.successfully.summary.model_info.Problem_type === 'regression' && <Box sx={{ display: 'flex' }}>*/}
                    {/*  <Typography sx={{ marginRight: '5px', fontWeight: 600 }}>BIC =</Typography>*/}
                    {/*  <Typography>{modelSummaryData.successfully.summary.Model_performance_metrics.BIC}</Typography>*/}
                    {/*</Box>}*/}
                  </Box>
                </Grid>
                {modelSummaryData.successfully.summary.model_info.Problem_type === 'classification' ? (
                  <Grid container>
                    <Grid item xs={6} style={{ padding: '16px 5px' }}>
                      <Box style={{ backgroundColor: '#EDF2F4' }}>
                        <Typography sx={{ fontSize: '16px', fontWeight: 600, textAlign: 'center' }}>
                          ROC-AUC curve
                        </Typography>
                        <LineChart modelSummaryData={modelSummaryData} isSum={true} />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        padding: '16px 5px'
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: '#EDF2F4',
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'space-evenly'
                        }}
                      >
                        <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>Confusion Matrix</Typography>
                        <table>
                          <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td colSpan={2} style={{ color: '#75b17e', textAlign: 'center' }}>
                              Actual Class
                            </td>
                            <td>&nbsp;</td>
                          </tr>
                          <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td style={{ color: '#000000' }}>Positive (1)</td>
                            <td style={{ color: '#000000' }}>Negative (0)</td>
                            <td style={{ color: '#000000' }}>Total</td>
                          </tr>
                          <tr>
                            <td style={{ color: '#e98f8e' }} rowSpan={2}>
                              <p className="rotated-cell">Predicted Class</p>
                            </td>
                            <td style={{ color: '#000000' }}>
                              <p className="rotated-cell">Positive (1)</p>
                            </td>
                            <td style={{ color: '#000000' }} className="false-cell">
                              TP = {modelSummaryData.successfully.summary?.Confusion_matrix?.TP}
                            </td>
                            <td style={{ color: '#000000' }} className="true-cell">
                              FP = {modelSummaryData.successfully.summary?.Confusion_matrix?.FP}
                            </td>
                            <td style={{ color: '#000000' }} className="neutral-cell">
                              {modelSummaryData.successfully.summary?.Confusion_matrix?.TP +
                                modelSummaryData.successfully.summary?.Confusion_matrix?.FP}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ color: '#000000' }}>
                              <p className="rotated-cell">Negative (0)</p>
                            </td>
                            <td style={{ color: '#000000' }} className="true-cell">
                              FN = {modelSummaryData.successfully.summary?.Confusion_matrix?.FN}
                            </td>
                            <td style={{ color: '#000000' }} className="false-cell">
                              TN = {modelSummaryData.successfully.summary?.Confusion_matrix?.TN}
                            </td>
                            <td style={{ color: '#000000' }} className="neutral-cell">
                              {modelSummaryData.successfully.summary?.Confusion_matrix?.FN +
                                modelSummaryData.successfully.summary?.Confusion_matrix?.TN}
                            </td>
                          </tr>
                          <tr>
                            <td>&nbsp;</td>
                            <td style={{ color: '#000000' }}>Total</td>
                            <td style={{ color: '#000000' }} className="neutral-cell">
                              {modelSummaryData.successfully.summary?.Confusion_matrix?.TP +
                                modelSummaryData.successfully.summary?.Confusion_matrix?.FN}
                            </td>
                            <td style={{ color: '#000000' }} className="neutral-cell">
                              {modelSummaryData.successfully.summary?.Confusion_matrix?.FP +
                                modelSummaryData.successfully.summary?.Confusion_matrix?.TN}
                            </td>
                            <td style={{ color: '#000000' }} className="neutral-cell">
                              {modelSummaryData.successfully.summary?.Confusion_matrix?.TN +
                                modelSummaryData.successfully.summary?.Confusion_matrix?.FP +
                                modelSummaryData.successfully.summary?.Confusion_matrix?.FN +
                                modelSummaryData.successfully.summary?.Confusion_matrix?.TP}
                            </td>
                          </tr>
                        </table>
                      </Box>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', padding: '16px 5px' }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          fontSize: '1.25rem',
                          color: '#000000'
                        }}
                      >
                        <p>Actual and Predicted values</p>
                        <RegressionChart data={modelSummaryData} />
                      </div>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        ) : modelSummaryStatus ? (
          // Handle other status codes here
          <div>
            <Typography variant="h6" color="error">
              Error: Unable to fetch data. Please try again later.
            </Typography>
            <Button onClick={onModalClose} color="primary">
              Close
            </Button>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: '1100px',
              margin: '0',
              height: '100vh'
            }}
          >
            <CircularProgress style={{ color: 'blue' }} />
          </div>
        )}
      </ModelSummaryStyled>
    </Dialog>
  );
};

export default ModelManagerSummaryDialog;
