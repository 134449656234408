import React, { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material';
import { dispatch, useAppSelector } from '../../store/hooks';
import { modalsMiddleware } from '../../store/slices/modals';
import { ModalName } from '../../types/modals';
import { setModelViewData, setModelViewStatus } from './../../store/slices/modelView/middleware';
import ModelViewInfoTable from '../ModelViewInfotable/index';
import { MbConverter } from '../../helpers/MbConverter';
import { modelViewMiddleware, modelViewSelector } from '../../store/slices/modelView';
import { removeLastExtension } from '../../helpers/removeExtension';

export interface ModelViewDialogProps {
  fileName: string;
  fileSize: number;
}

const columns = [
  { field: 'column_name', headerName: 'Column name' },
  { field: 'type', headerName: 'Type' }
];

const DatasourceSummaryDialog = ({ fileName, fileSize }: ModelViewDialogProps) => {
  const userId = localStorage.getItem('userId');
  const getModelViewUrl = `/model_summary/get_fields?container_name=containerfromblobservice${userId}&model_name=${fileName}`;
  const { modelViewData, modelViewStatus } = useAppSelector(modelViewSelector.modelViewData);

  useEffect(() => {
    dispatch(modelViewMiddleware.getModelView(getModelViewUrl));
  }, []);

  const onModalClose = () => {
    dispatch(modalsMiddleware.closeModal(ModalName.ModelViewModal));
    dispatch(setModelViewData({}));
    dispatch(setModelViewStatus(0));
  };

  return (
    <Dialog fullWidth maxWidth="lg" onClose={onModalClose} aria-labelledby="simple-dialog-title" open>
      <Box>
        <DialogTitle style={{ textAlign: 'center', position: 'relative' }} id="draggable-dialog-title">
          Model View
          <IconButton
            onClick={onModalClose}
            sx={{
              position: 'absolute',
              right: 20,
              top: 10
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Card>
          <CardHeader
            sx={{ textAlign: 'center' }}
            title={`${removeLastExtension(fileName)} / ${MbConverter(fileSize)}`}
          />
          {modelViewStatus === 200 ? (
            <CardContent sx={{ pt: 0 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '30px',
                  padding: '8px',
                  width: '100%',
                  '& .MuiPaper-root': {
                    width: '100%'
                  }
                }}
              >
                <ModelViewInfoTable data={modelViewData} />
              </Box>
            </CardContent>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '1100px',
                margin: '0',
                height: '100vh'
              }}
            >
              <CircularProgress style={{ color: 'blue' }} />
            </div>
          )}
        </Card>
      </Box>
    </Dialog>
  );
};

export default DatasourceSummaryDialog;
