import React from 'react';

export const containerStyles: React.CSSProperties = {
  padding: '30px 0px',
  display: 'flex',
  color: '#000',
  position: 'relative',
  justifyContent: 'center',
  gap: '20px'
};

export const dropdownStyles: React.CSSProperties = {
  marginRight: '20px',
  width: '100%',
  flex: '1'
};
export const autocompleteStyles: React.CSSProperties = {
  width: '100%',
  display: 'flex'
};

export const removeIconStyles: React.CSSProperties = {
  color: 'red',
  paddingTop: '45px',
  paddingLeft: '25px',
  display: 'flex',
  alignItems: 'flex-start'
};
