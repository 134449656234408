import { dispatch } from '../../../store/hooks';
import { modalsMiddleware } from '../../../store/slices/modals';
import { IDashboardAction } from '../../../types';
import { ModalName } from '../../../types/modals';
import { modelsMiddleware } from '../../../store/slices/models';

export const summaryDialogOpen = ({ fileName }: { fileName: string }, { fileSize }: { fileSize: number }) => {
  dispatch(modalsMiddleware.openModal({ name: ModalName.ModelManagerSummaryDialog, props: { fileName, fileSize } }));
};
export const urlDialogOpen = (
  { fileName }: { fileName: string },
  { fileSize }: { fileSize: number },
  { setChecked }: { setChecked: any },
  { shapValueWanted }: { shapValueWanted: boolean }
) => {
  dispatch(
    modalsMiddleware.openModal({
      name: ModalName.urlDialog,
      props: { fileName, fileSize, setChecked, shapValueWanted }
    })
  );
};

export const modelViewDialogOpen = ({ fileName }: { fileName: string }, { fileSize }: { fileSize: number }) => {
  dispatch(modalsMiddleware.openModal({ name: ModalName.ModelViewModal, props: { fileName, fileSize } }));
};
export const inferenceDialogOpen = ({ fileName }: { fileName: string }, { fileSize }: { fileSize: number }) => {
  dispatch(
    modalsMiddleware.openModal({
      name: ModalName.ModelManagerInferenceDialog,
      props: {
        fileName,
        fileSize
      }
    })
  );
};

export const deleteDialogOpen = (
  { fileName }: { fileName: string },
  { fileSize }: { fileSize: number },
  { setChecked }: { setChecked: number },
  { shapValueWanted }: { shapValueWanted: boolean },
  { isModels }: { isModels: boolean },
  { selected }: { selected: any },
  { setSelected }: { setSelected: any }
) => {
  dispatch(
    modalsMiddleware.openModal({
      name: ModalName.DeleteModal,
      props: { fileName, fileSize, shapValueWanted, isModels, selected, setSelected }
    })
  );
};

export const deployUndeployButton = ({ fileName }: { fileName: string }) => {
  dispatch(modelsMiddleware.setLoadingModel(fileName));
};

export const ModelActions: IDashboardAction[] = [
  {
    id: 'View',
    title: 'View',
    name: 'view',
    style: {
      backgroundColor: '#FCA311',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#F77F00'
      }
    },
    color: 'primary',
    state: true,
    openFunction: modelViewDialogOpen
  },
  {
    id: 'Summary',
    title: 'Summary',
    name: 'summary',
    style: {
      backgroundColor: '#3DA5D9',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#2364AA'
      }
    },
    color: 'primary',
    state: true,
    openFunction: summaryDialogOpen
  },
  {
    id: 'Inference',
    title: 'Predict',
    name: 'inference',
    style: {
      backgroundColor: '#0EAD96',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#1A936F'
      }
    },
    color: 'error',
    state: true,
    openFunction: inferenceDialogOpen
  },
  // {
  //   id: 'View_url',
  //   title: 'URL',
  //   name: 'View URL',
  //   style: {
  //     color: '#ffffff',
  //     backgroundColor: '#0EAD96',
  //     '&:hover': {
  //       backgroundColor: '#1A936F'
  //     }
  //   },
  //   color: 'secondary',
  //   state: true
  // },
  {
    id: 'Deploy',
    title: 'Deploy',
    name: 'deploy',
    style: {
      color: '#ffffff',
      backgroundColor: '#0EAD96',
      '&:hover': {
        backgroundColor: '#1A936F'
      }
    },
    color: 'secondary',
    state: true,
    openFunction: deployUndeployButton,
    valueOnClick: {
      hideMe: true,
      field: 'Undeploy'
    }
  },
  {
    id: 'Undeploy',
    title: 'Undeploy',
    name: 'undeploy',
    style: {
      backgroundColor: '#FCA311',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#F77F00'
      }
    },
    color: 'warning',
    state: false,
    openFunction: deployUndeployButton,
    valueOnClick: {
      hideMe: true,
      field: 'Deploy'
    }
  },
  {
    id: 'Drop',
    title: 'Drop',
    name: 'drop',
    style: {
      backgroundColor: '#EF233C',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#D90429'
      }
    },
    color: 'error',
    state: true,
    openFunction: deleteDialogOpen
  }
];
