export const top100Films = [
  { label: 'The Dark Knight', id: 1 },
  { label: 'Control with Control', id: 2 },
  { label: 'Combo with Solo', id: 3 },
  { label: 'The Dark', id: 4 },
  { label: 'Fight Club', id: 5 },
  { label: 'demo@company.com', id: 6 },
  { label: 'Pulp Fiction', id: 7 },
  { label: 'Merge Operation', id: 8 },
  { label: 'The Dark Knight', id: 1 },
  { label: 'Control with Control', id: 2 },
  { label: 'Combo with Solo', id: 3 },
  { label: 'The Dark', id: 4 },
  { label: 'Fight Club', id: 5 },
  { label: 'demo@company.com', id: 6 },
  { label: 'Pulp Fiction', id: 7 },
  { label: 'Merge Operation', id: 8 }
];
export const classificationMetricsOptions = [
  { label: 'AUC', id: 1 },
  { label: 'Accuracy', id: 2 },
  { label: 'Precision', id: 3 },
  { label: 'Recall', id: 4 },
  // { label: 'TruePositives', id: 5 },
  // { label: 'TrueNegatives', id: 6 },
  { label: 'F1_Score', id: 6 },
  // { label: 'AUC (prec-recall)', id: 8 }
];

export const penaltyOptions = [
  { label: 'none', id: 1 },
  { label: 'l1', id: 2 },
  { label: 'l2', id: 3 },
  { label: 'elasticnet', id: 4 },
];

export const solverOptions = [
  { label: 'lbfgs', id: 1 },
  { label: 'liblinear', id: 2 },
  { label: 'newton-cg', id: 3 },
  { label: 'saga', id: 4 },
];

export const activationFunctionOptions = [
  { label: 'ReLU', value: 1 },
  { label: 'Leaky ReLU', value: 2 },
  { label: 'Sigmoid', value: 3 },
  { label: '’Tanh', value: 4 }
];

export const layerOptions = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 }
];

export const regressionMetricsOptions = [
  { label: 'RMSE', id: 1 },
  { label: 'MSE', id: 2 },
  { label: 'MAE', id: 3 },
  { label: 'R2', id: 4 },
  { label: 'Adjusted_R2', id: 5 },
  // { label: 'AIC', id: 6 },
  // { label: 'BIC', id: 7 },
  // { label: 'Deviation', id: 8 }
];

export const MergeTypes = [
  { label: 'Join Left', id: 1 },
  { label: 'Join Right', id: 2 },
  { label: 'Join Other', id: 3 }
];

export const TrainModels = [
  { label: 'LL', checked: false }
  // { label: 'XGBoost', checked: false },
  // { label: 'AI', checked: false },
  // { label: 'C3', checked: false },
  // { label: 'Machina', checked: false },
  // { label: 'Ex', checked: false },
  // { label: 'BU', checked: false },
  // { label: 'Cat', checked: false },
  // { label: 'Engine', checked: false }
];

export const chartData = [
  {
    name: 'Series 1',
    data: [31, 40, 28, 51, 42, 109, 100]
  },
  {
    name: 'Series 2',
    data: [11, 32, 45, 32, 34, 52, 41]
  }
];
