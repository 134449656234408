import API from '../../../manager/API';
import { dispatch } from '../../hooks';
import slice from './slice';

const { setDeployStatusData, setDeployStStatus } = slice.actions;

const getDeployStatus = (url: any) => async () => {
  try {
    const response = await API.deployStatus.getDeployStatus(url);
    dispatch(setDeployStatusData(response.data));
    dispatch(setDeployStStatus(response.status));
    return { response, status: response.status, data: response.data };
  } catch (error) {}
};

export default {
  getDeployStatus
};
