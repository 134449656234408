export enum ModalName {
  NONE = '',
  ModelManagerSummaryDialog = 'ModelManagerSummaryDialogProps',
  ModelManagerInferenceDialog = 'ModelManagerInferenceDialog',
  DatasourceSummaryModal = 'DatasourceSummaryModal',
  PreviewModal = 'PreviewModal',
  DeleteModal = 'DeleteModal',
  PreviewClickModal = 'PreviewClickModal',
  ShareInfoModal = 'ShareInfoModal',
  ModelViewModal = 'ModelViewModal',
  NNInfoModal = 'NNInfoModal',
  LogisticRegressionInfoModal = 'LogisticRegressionInfoModal',
  XGBoostInfoModal = 'XGBoostInfoModal',
  urlDialog = 'urlDialog'
}

export enum SeveritiesType {
  success = 'success',
  error = 'error',
  info = 'info',
  warning = 'warning'
}
